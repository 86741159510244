import * as styles from "./AllArticles.module.scss";
import React, { useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import WhiteCard from "../../molecules/WhiteCard/WhiteCard";
import { FiSearch } from "react-icons/fi";
import { articlesTags } from "../../../constants/tagsData";
import image from "../../../images/artbanner.png";
function AllArticles() {
  const [searchTerm, setSearchTerm] = useState("");

  function handleSearchChange(event) {
    setSearchTerm(event.target.value);
  }

  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost {
          edges {
            node {
              articleTags {
                tags
              }
              articlesD {
                bannerImage {
                  node {
                    sourceUrl
                  }
                }
              }
              shortDescription {
                shortText
                articleCategory
              }
              slug
              title
            }
          }
        }
      }
    `
  );
  const searchResults = allWpPost.edges.filter(
    (post) =>
      post?.node?.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      post?.node?.articleTags?.tags?.some((tag) =>
        tag.toLowerCase().includes(searchTerm.toLowerCase())
      ) ||
      post?.node?.shortDescription?.articleCategory
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase())
  );
  const half = Math.ceil(searchResults.length / 2);
  const firstHalf = searchResults.slice(0, half);
  const secondHalf = searchResults.slice(half);

  function handleClear() {
    setSearchTerm("");
  }
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div
      className={`${styles.container}  ${
        offset >= 1300 && `${styles.container1}`
      } ${offset >= 2500 && `${styles.container2}`} ${
        offset >= 4000 && `${styles.container3}`
      } ${offset >= 5000 && `${styles.container4}`}  `}
    >

      <div className={styles.inputgroup}>
        <FiSearch />
        <input
          value={searchTerm}
          onChange={handleSearchChange}
          type={"text"}
          placeholder="Article Search..."
        />
        {searchTerm !== "" && (
          <button onClick={handleClear} className={styles.show}>
            Show All
          </button>
        )}
      </div>
      <div className={styles.tags}>
        <button className={styles.searchIcon} onClick={() => setSearchTerm("")}>
          <FiSearch /> Show All
        </button>
        {articlesTags.map((item, i) => {
          return (
            <button
              className={searchTerm?.includes(item) ? styles.activeTerm : ``}
              key={i}
              onClick={() => setSearchTerm(item)}
            >
              {item}
            </button>
          );
        })}
      </div>

      <div className={styles.data}>
        {firstHalf.map((post) => {
          return (
            <>
              <WhiteCard
                desc={post?.node?.shortDescription?.shortText}
                image={post?.node?.articlesD?.bannerImage?.sourceUrl}
                title={post?.node?.title}
                link={`/blog/${post?.node?.slug}`}
                tag={post?.node?.shortDescription?.articleCategory}
                extraClass="allarticles"
              />
            </>
          );
        })}
      </div>
     {searchTerm==="" &&  <div className={styles.banner}>
        <img src={image} alt="article banner"/>
        <div className={styles.content}>
          <p>
            “The elevator to success is out of order. You’ll have to use the
            stairs, one step at a time.” <br />
            <br />
            <span>Joe Girard</span>
          </p>
        </div>
      </div>}
      <div className={`${styles.data} ${styles.secondHalf}`}>
        {secondHalf.map((post) => {
          return (
            <>
              <WhiteCard
                desc={post?.node?.shortDescription?.shortText}
                image={post?.node?.articlesD?.bannerImage?.sourceUrl}
                title={post?.node?.title}
                link={`/blog/${post?.node?.slug}`}
                tag={post?.node?.shortDescription?.articleCategory}
                extraClass="allarticles"
              />
            </>
          );
        })}
      </div>
      {searchResults?.length === 0 && (
        <div className={styles.nocontent}>
          <h3>
            No Content Found with <span>"{searchTerm}"</span>
          </h3>
        </div>
      )}
      <div className={styles.bottomD}>
        <p>
          <br /> Keep Exploring the Magic of Communications <br />{" "}
          <span className={styles.bottomline}>
            America - Europe - Africa - Asia
          </span>{" "}
        </p>
      </div>
      <div className={styles.bottom}></div>
      <div className={styles.ref}>
        <Link to="/demo">
          <p>
            Check out our product <span> Show Room</span>
          </p>
        </Link>
      </div>
    </div>
  );
}
export default AllArticles;
