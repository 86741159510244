import React from "react";
import * as styles from "./ArticleBanner.module.scss";
import Slider from "react-slick";
import "../../../styles/slick-theme.scss";
import "../../../styles/slick.scss";
import image2 from "../../../images/banner-articles.png";


function ArticleBanner() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    arrows: false,
    pauseOnHover: false,
    speed: 1000,
    fade:true
    
  };
  const images = [
{
  image:image2,
  extraClass:"lastimage"
}
  ];
  return (
    <div className={styles.main}>
      <div className={styles.slider}>
        <Slider {...settings}>
          {images.map((item, i) => {
            return (
              <div className={`${styles.demoimg} ${item.extraClass}`} >
                <img alt="article" className={`${item.extraClass}`} src={item.image} />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={styles.content}>
          <h1 className={styles.maintitle}>Explore & Enjoy </h1>
          {/* <p className={styles.info}>Explore our Articles </p> */}
        </div>
        </div>
    );
}
export default ArticleBanner;
