// extracted by mini-css-extract-plugin
export var behindImg = "ArticleBanner-module--behindImg--10b85";
export var content = "ArticleBanner-module--content--9abc7";
export var demoBanner = "ArticleBanner-module--demoBanner--46829";
export var desc = "ArticleBanner-module--desc--72f8a";
export var discover = "ArticleBanner-module--discover--b405a";
export var imageTop = "ArticleBanner-module--imageTop--a9f84";
export var info = "ArticleBanner-module--info--59793";
export var lastimage = "ArticleBanner-module--lastimage--9b3d5";
export var main = "ArticleBanner-module--main--f7c5c";
export var maintitle = "ArticleBanner-module--maintitle--647dc";
export var slider = "ArticleBanner-module--slider--c11ef";
export var sneak = "ArticleBanner-module--sneak--f6bfa";
export var space = "ArticleBanner-module--space--937e2";
export var spanheading = "ArticleBanner-module--spanheading--f612c";
export var videos = "ArticleBanner-module--videos--6d52f";