import { Link } from "gatsby";
import React from "react";
import * as styles from "./WhiteCard.module.scss";


function WhiteCard({image,tag,title,desc,link,extraClass}){
    const MAX_LENGTH = 85;
    return(
        <Link to={`${link}`}>
        <div className={`${styles.whitecard} ${styles[extraClass]}`}>
            <div style={{backgroundImage:`url(${image})`}} className={styles.image}/>
           <div className={styles.content}>
           <h3
           style={{color:`${tag==="Productivity" ? `#4A74BE` : tag==="Lifestyle" ? `#3787C1`: tag==="Creativity" ? `#ec7600` : `#078367`}`}}
           >{tag}</h3>
            <h2>{title}</h2>
            {/* <p   dangerouslySetInnerHTML={{ __html: desc}}></p> */}
            <p dangerouslySetInnerHTML={desc?.length > MAX_LENGTH ? {__html: `${desc?.substring(0,MAX_LENGTH)}...`} : {__html:desc}}></p>
            {/* <button>Read More</button> */}
           </div>
        </div>
        </Link>
    );
}

export default WhiteCard;