import React from "react";
import Header from "../components/atoms/header";
import AllArticles from "../components/ogranisms/AllArticles/AllArticles";
import ArticleBanner from "../components/ogranisms/ArticleBanner/ArticleBanner";


function Articles(){
    return(
        <>
        <Header />
        <ArticleBanner />
        <AllArticles />
        </>
    );
}
export default Articles;
